import React from 'react'
import { inject, observer } from 'mobx-react'
import { mountHook } from 'uikit'

const Page = ({ AuthModel }) => {
  mountHook(() => {
    AuthModel.CommonModel.applyUnknownHistory()
  })

  return null
}

export default inject('AuthModel')(observer(Page))
